import React from 'react'
import { withSnackbar } from 'notistack'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import Page from './page'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  tableHeader: {
    fontWeight: 800,
  },
  sections: {
    width: '1000px',
    maxWidth: '90vw',
    backgroundColor: 'white',
    boxShadow: '0 0 85px 2px rgba(228, 105, 105, 0.2)',
    padding: theme.spacing(5),
    marginLeft: theme.spacing(1),
  },
  title: {
    fontWeight: 900,
    fontSize: 'x-large',
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 'large',
  },
  table: {
    boxShadow: 'none',
  },
}))

function createData(category, examples, collected) {
  return { category, examples, collected }
}

const rows = [
  createData(
    'A. Identifiers',
    'Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name',
    'YES'
  ),
  createData(
    'B. Personal information categories listed in the California Customer Records statute',
    'Name, contact information, education, employment, employment history and financial information',
    'YES'
  ),
  createData(
    'C. Protected classification characteristics under California or federal law',
    'Gender and date of birth',
    'NO'
  ),
  createData(
    'D. Commercial information',
    'Transaction information, purchase history, financial details and payment information',
    'YES'
  ),
  createData('E. Biometric information', 'Fingerprints and voiceprints', 'NO'),
  createData(
    'F. Internet or other similar network activity',
    'Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements',
    'NO'
  ),
  createData('G. Geolocation data', '	Device location', 'NO'),
  createData(
    'H. Audio, electronic, visual, thermal, olfactory, or similar information',
    'Images and audio, video or call recordings created in connection with our business activities',
    'NO'
  ),
  createData(
    'I. Professional or employment-related information',
    'Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us',
    'NO'
  ),
  createData(
    'J. Education Information',
    'Student records and directory information',
    'NO'
  ),
  createData(
    'K. Inferences drawn from other personal information',
    'Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics',
    'YES'
  ),
]

function Home() {
  const classes = useStyles()

  function content() {
    return (
      <>
        <Grid className={classes.container}>
          <Grid className={classes.sections}>
            <Typography className={classes.title}>PRIVACY NOTICE</Typography>
            <br />
            <Typography>Last updated August 12, 2021</Typography>
            <br />
            <br />
            <br />
            <Typography>
              Thank you for choosing to be part of our community at Made to
              Uplift ("Company", "we", "us", "our"). We are committed to
              protecting your personal information and your right to privacy. If
              you have any questions or concerns about this privacy notice, or
              our practices with regards to your personal information, please
              contact us at info@madetouplift.com.
            </Typography>
            <br />
            <Typography>
              When you visit our website &nbsp;
              <a href="https://madetouplift.com/" target="_blank">
                https://madetouplift.com
              </a>
              &nbsp; (the "Website"), and more generally, use any of our
              services (the "Services", which include the Website), we
              appreciate that you are trusting us with your personal
              information. We take your privacy very seriously. In this privacy
              notice, we seek to explain to you in the clearest way possible
              what information we collect, how we use it and what rights you
              have in relation to it. We hope you take some time to read through
              it carefully, as it is important. If there are any terms in this
              privacy notice that you do not agree with, please discontinue use
              of our Services immediately.
            </Typography>
            <br />
            <Typography>
              This privacy notice applies to all information collected through
              our Services (which, as described above, includes our Website), as
              well as, any related services, sales, marketing or events.
            </Typography>
            <br />
            <Typography>
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              TABLE OF CONTENTS
            </Typography>
            <br />
            <Grid>
              <div>
                <a href="#1">1. WHAT INFORMATION DO WE COLLECT?</a>
              </div>
              <div>
                <a href="#2">2. HOW DO WE USE YOUR INFORMATION?</a>
              </div>
              <div>
                <a href="#3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
              </div>
              <div>
                <a href="#4">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
              </div>
              <div>
                <a href="#5">
                  5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </a>
              </div>
              <div>
                <a href="#6">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
              </div>
              <div>
                <a href="#7">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </div>
              <div>
                <a href="#8">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </div>
              <div>
                <a href="#9">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </div>
              <div>
                <a href="#10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </div>
              <div>
                <a href="#11">
                  11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </a>
              </div>
              <div>
                <a href="#12">12. DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </div>
              <div>
                <a href="#13">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              </div>
              <div>
                <a href="#14">
                  14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </a>
              </div>
              <div>
                <br />
              </div>
            </Grid>
            <Typography className={classes.subtitle}>
              <a id="1">1. WHAT INFORMATION DO WE COLLECT?</a>
            </Typography>
            <br />
            <Typography>
              <u>We collect some personal information.</u>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>We collect personal information that you provide to us.</em>
            </Typography>
            <br />
            <Typography>
              We collect personal information that you voluntarily provide to us
              when you register on the Website, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the Website or otherwise when you
              contact us.
            </Typography>
            <br />
            <Typography>
              The personal information that we collect depends on the context of
              your interactions with us and the Website, the choices you make
              and the products and features you use. The personal information we
              collect may include the following:
            </Typography>
            <br />
            <Typography>
              Personal Information Provided by You. We collect names; email
              addresses; mailing addresses; contact preferences; billing
              addresses; debit/credit card numbers; and other similar
              information.
            </Typography>
            <br />
            <Typography>
              Payment Data. We may collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number (such as a credit card number), and the security code
              associated with your payment instrument. All payment data is
              stored by Stripe. You may find their privacy notice link(s) here:
              &nbsp;
              <a href="https://stripe.com/privacy" target="_blank">
                https://stripe.com/privacy
              </a>
              .
            </Typography>
            <br />
            <Typography>
              Social Media Login Data. We may provide you with the option to
              register with us using your existing social media account details,
              like your Facebook, Twitter or other social media account. If you
              choose to register in this way, we will collect the information
              described in the section called "
              <a href="#6">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.
            </Typography>
            <br />
            <Typography>
              All personal information that you provide to us must be true,
              complete and accurate, and you must notify us of any changes to
              such personal information.
            </Typography>
            <br />
            <Typography>
              <u>Information automatically collected</u>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                Some information — such as your Internet Protocol (IP) address
                and/or browser and device characteristics — is collected
                automatically when you visit our Website.
              </em>
            </Typography>
            <br />
            <Typography>
              We automatically collect certain information when you visit, use
              or navigate the Website. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Website and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Website, and for our
              internal analytics and reporting purposes.
            </Typography>
            <br />
            <Typography>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </Typography>
            <br />
            <Typography>
              <em>The information we collect includes:</em>
            </Typography>
            <Typography>
              <ul>
                <li>
                  <em>Log and Usage Data.</em>
                  Log and usage data is service-related, diagnostic, usage and
                  performance information our servers automatically collect when
                  you access or use our Website and which we record in log
                  files. Depending on how you interact with us, this log data
                  may include your IP address, device information, browser type
                  and settings and information about your activity in the
                  Website (such as the date/time stamps associated with your
                  usage, pages and files viewed, searches and other actions you
                  take such as which features you use), device event information
                  (such as system activity, error reports (sometimes called
                  'crash dumps') and hardware settings).
                </li>
              </ul>
              <ul>
                <li>
                  <em>Device Data.</em>
                  We collect device data such as information about your
                  computer, phone, tablet or other device you use to access the
                  Website. Depending on the device used, this device data may
                  include information such as your IP address (or proxy server),
                  device and application identification numbers, location,
                  browser type, hardware model Internet service provider and/or
                  mobile carrier, operating system and system configuration
                  information.
                </li>
              </ul>
              <ul>
                <li>
                  <em>Location Data.</em>
                  We collect location data such as information about your
                  device's location, which can be either precise or imprecise.
                  How much information we collect depends on the type and
                  settings of the device you use to access the Website. For
                  example, we may use GPS and other technologies to collect
                  geolocation data that tells us your current location (based on
                  your IP address). You can opt out of allowing us to collect
                  this information either by refusing access to the information
                  or by disabling your Location setting on your device. Note
                  however, if you choose to opt out, you may not be able to use
                  certain aspects of the Services.
                </li>
              </ul>
            </Typography>
            <Typography>
              <u>Information collected from other sources</u>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                We may collect limited data from public databases, marketing
                partners, social media platforms, and other outside sources.
              </em>
            </Typography>
            <br />
            <Typography>
              In order to enhance our ability to provide relevant marketing,
              offers and services to you and update our records, we may obtain
              information about you from other sources, such as public
              databases, joint marketing partners, affiliate programs, data
              providers, social media platforms, as well as from other third
              parties. This information includes mailing addresses, job titles,
              email addresses, phone numbers, intent data (or user behavior
              data), Internet Protocol (IP) addresses, social media profiles,
              social media URLs and custom profiles, for purposes of targeted
              advertising and event promotion. If you interact with us on a
              social media platform using your social media account (e.g.
              Facebook or Twitter), we receive personal information about you
              such as your name, email address, and gender. Any personal
              information that we collect from your social media account depends
              on your social media account's privacy settings.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="2"> 2. HOW DO WE USE YOUR INFORMATION?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                We process your information for purposes based on legitimate
                business interests, the fulfillment of our contract with you,
                compliance with our legal obligations, and/or your consent.
              </em>
            </Typography>
            <br />
            <Typography>
              We use personal information collected via our Website for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </Typography>
            <br />
            <Typography>
              <em>We use the information we collect or receive:</em>
            </Typography>
            <br />
            <Typography>
              <ul>
                <li>
                  To facilitate account creation and logon process. If you
                  choose to link your account with us to a third-party account
                  (such as your Google or Facebook account), we use the
                  information you allowed us to collect from those third parties
                  to facilitate account creation and logon process for the
                  performance of the contract. See the section below headed "
                  <a href="#6">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" for
                  further information.
                </li>
              </ul>
              <ul>
                <li>
                  To post testimonials. We post testimonials on our Website that
                  may contain personal information. Prior to posting a
                  testimonial, we will obtain your consent to use your name and
                  the content of the testimonial. If you wish to update, or
                  delete your testimonial, please contact us at
                  info@madetouplift.com and be sure to include your name,
                  testimonial location, and contact information.
                </li>
              </ul>
              <ul>
                <li>
                  Request feedback. We may use your information to request
                  feedback and to contact you about your use of our Website.
                </li>
              </ul>
              <ul>
                <li>
                  To enable user-to-user communications. We may use your
                  information in order to enable user-to-user communications
                  with each user's consent.
                </li>
              </ul>
              <ul>
                <li>
                  To manage user accounts. We may use your information for the
                  purposes of managing our account and keeping it in working
                  order.
                </li>
              </ul>
              <ul>
                <li>
                  To send administrative information to you. We may use your
                  personal information to send you product, service and new
                  feature information and/or information about changes to our
                  terms, conditions, and policies.
                </li>
              </ul>
              <ul>
                <li>
                  To protect our Services. We may use your information as part
                  of our efforts to keep our Website safe and secure (for
                  example, for fraud monitoring and prevention).
                </li>
              </ul>
              <ul>
                <li>
                  To enforce our terms, conditions and policies for business
                  purposes, to comply with legal and regulatory requirements or
                  in connection with our contract.
                </li>
              </ul>
              <ul>
                <li>
                  To respond to legal requests and prevent harm. If we receive a
                  subpoena or other legal request, we may need to inspect the
                  data we hold to determine how to respond.
                </li>
              </ul>
              <ul>
                <li>
                  Fulfill and manage your orders. We may use your information to
                  fulfill and manage your orders, payments, returns, and
                  exchanges made through the Website.
                </li>
              </ul>
              <ul>
                <li>
                  Administer prize draws and competitions. We may use your
                  information to administer prize draws and competitions when
                  you elect to participate in our competitions.
                </li>
              </ul>
              <ul>
                <li>
                  To deliver and facilitate delivery of services to the user. We
                  may use your information to provide you with the requested
                  service.
                </li>
              </ul>
              <ul>
                <li>
                  To respond to user inquiries/offer support to users. We may
                  use your information to respond to your inquiries and solve
                  any potential issues you might have with the use of our
                  Services.
                </li>
              </ul>
              <ul>
                <li>
                  To send you marketing and promotional communications. We
                  and/or our third-party marketing partners may use the personal
                  information you send to us for our marketing purposes, if this
                  is in accordance with your marketing preferences. For example,
                  when expressing an interest in obtaining information about us
                  or our Website, subscribing to marketing or otherwise
                  contacting us, we will collect personal information from you.
                  You can opt-out of our marketing emails at any time (see the "
                  <a href="#6">WHAT ARE YOUR PRIVACY RIGHTS?</a>" below).
                </li>
              </ul>
              <ul>
                <li>
                  Deliver targeted advertising to you. We may use your
                  information to develop and display personalized content and
                  advertising (and work with third parties who do so) tailored
                  to your interests and/or location and to measure its
                  effectiveness.
                </li>
              </ul>
              <ul>
                <li>
                  For other business purposes. We may use your information for
                  other business purposes, such as data analysis, identifying
                  usage trends, determining the effectiveness of our promotional
                  campaigns and to evaluate and improve our Website, products,
                  marketing and your experience. We may use and store this
                  information in aggregated and anonymized form so that it is
                  not associated with individual end users and does not include
                  personal information. We will not use identifiable personal
                  information without your consent.
                </li>
              </ul>
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short:</em>
              <em>
                We only share information with your consent, to comply with
                laws, to provide you with services, to protect your rights, or
                to fulfill business obligations.
              </em>
            </Typography>
            <br />
            <Typography>
              We may process or share your data that we hold based on the
              following legal basis:
            </Typography>
            <Typography>
              <ul>
                <li>
                  Consent: We may process your data if you have given us
                  specific consent to use your personal information for a
                  specific purpose.
                </li>
              </ul>
              <ul>
                <li>
                  Legitimate Interests: We may process your data when it is
                  reasonably necessary to achieve our legitimate business
                  interests.
                </li>
              </ul>
              <ul>
                <li>
                  Performance of a Contract: Where we have entered into a
                  contract with you, we may process your personal information to
                  fulfill the terms of our contract.
                </li>
              </ul>
              <ul>
                <li>
                  Legal Obligations: We may disclose your information where we
                  are legally required to do so in order to comply with
                  applicable law, governmental requests, a judicial proceeding,
                  court order, or legal process, such as in response to a court
                  order or a subpoena (including in response to public
                  authorities to meet national security or law enforcement
                  requirements).
                </li>
              </ul>
              <ul>
                <li>
                  Vital Interests: We may disclose your information where we
                  believe it is necessary to investigate, prevent, or take
                  action regarding potential violations of our policies,
                  suspected fraud, situations involving potential threats to the
                  safety of any person and illegal activities, or as evidence in
                  litigation in which we are involved.
                </li>
              </ul>
            </Typography>
            <Typography>
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </Typography>
            <Typography>
              <ul>
                <li>
                  Business Transfers. We may share or transfer your information
                  in connection with, or during negotiations of, any merger,
                  sale of company assets, financing, or acquisition of all or a
                  portion of our business to another company.
                </li>
              </ul>
              <ul>
                <li>
                  Vendors, Consultants and Other Third-Party Service Providers.
                  We may share your data with third-party vendors, service
                  providers, contractors or agents who perform services for us
                  or on our behalf and require access to such information to do
                  that work. Examples include: payment processing, data
                  analysis, email delivery, hosting services, customer service
                  and marketing efforts. We may allow selected third parties to
                  use tracking technology on the Website, which will enable them
                  to collect data on our behalf about how you interact with our
                  Website over time. This information may be used to, among
                  other things, analyze and track data, determine the popularity
                  of certain content, pages or features, and better understand
                  online activity. Unless described in this notice, we do not
                  share, sell, rent or trade any of your information with third
                  parties for their promotional purposes.
                </li>
              </ul>
              <ul>
                <li>
                  Affiliates. We may share your information with our affiliates,
                  in which case we will require those affiliates to honor this
                  privacy notice. Affiliates include our parent company and any
                  subsidiaries, joint venture partners or other companies that
                  we control or that are under common control with us.
                </li>
              </ul>
              <ul>
                <li>
                  Business Partners. We may share your information with our
                  business partners to offer you certain products, services or
                  promotions.
                </li>
              </ul>
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="4">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short:</em>
              <em>
                We only share information with the following categories of third
                parties.
              </em>
            </Typography>
            <br />
            <Typography>
              We only share and disclose your information with the following
              categories of third parties. If we have processed your data based
              on your consent and you wish to revoke your consent, please
              contact us using the contact details provided in the section below
              titled "
              <a href="#13">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              ".
            </Typography>
            <Typography>
              <ul>
                <li>Ad Networks</li>
              </ul>
              <ul>
                <li>Sales &amp; Marketing Tools</li>
              </ul>
              <ul>
                <li>Social Networks</li>
              </ul>
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="5">
                5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                We may use cookies and other tracking technologies to collect
                and store your information.
              </em>
            </Typography>
            <br />
            <Typography>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="6">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                If you choose to register or log in to our services using a
                social media account, we may have access to certain information
                about you.
              </em>
            </Typography>
            <br />
            <Typography>
              Our Website offers you the ability to register and login using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, profile picture as well as
              other information you choose to make public on such social media
              platform.
            </Typography>
            <br />
            <Typography>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Website. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use and share your personal information, and how you
              can set your privacy preferences on their sites and apps.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="7">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                We keep your information for as long as necessary to fulfill the
                purposes outlined in this privacy notice unless otherwise
                required by law.
              </em>
            </Typography>
            <br />
            <Typography>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.
            </Typography>
            <br />
            <Typography>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="8">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                We aim to protect your personal information through a system of
                organizational and technical security measures.
              </em>
            </Typography>
            <br />
            <Typography>
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, despite our safeguards and
              efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security, and improperly collect,
              access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of
              personal information to and from our Website is at your own risk.
              You should only access the Website within a secure environment.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="9">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </Typography>
            <br />
            <Typography>
              <em>In Short:</em>
              <em>
                You may review, change, or terminate your account at any time.
              </em>
            </Typography>
            <br />
            <Typography>
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                rel="noopener noreferrer"
                target="_blank"
              >
                &nbsp;
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </Typography>
            <br />
            <Typography>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                &nbsp; https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
              .
            </Typography>
            <br />
            <Typography>
              If you have questions or comments about your privacy rights, you
              may email us at info@madetouplift.com.
            </Typography>
            <br />
            <Typography>
              <u>Account Information</u>
            </Typography>
            <br />
            <Typography>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </Typography>
            <Typography>
              <ul>
                <li>Contact us using the contact information provided.</li>
              </ul>
            </Typography>
            <br />
            <Typography>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </Typography>
            <br />

            <Typography>
              <u>Cookies and similar technologies:</u>
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Website. To opt-out of interest-based advertising by
              advertisers on our Website visit
              <a
                href="http://www.aboutads.info/choices/"
                rel="noopener noreferrer"
                target="_blank"
              >
                &nbsp; http://www.aboutads.info/choices/
              </a>
              .
            </Typography>
            <br />
            <Typography>
              <u>Opting out of email marketing:</u>
              You can unsubscribe from our marketing email list at any time by
              clicking on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list — however, we may still
              communicate with you, for example to send you service-related
              emails that are necessary for the administration and use of your
              account, to respond to service requests, or for other
              non-marketing purposes. To otherwise opt-out, you may:
            </Typography>
            <Typography>
              <ul>
                <li>Contact us using the contact information provided.</li>
              </ul>
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="10">10. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </Typography>
            <br />
            <Typography>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="11">
                11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </Typography>
            <br />
            <Typography>
              <em>In Short: </em>
              <em>
                Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.
              </em>
            </Typography>
            <br />
            <Typography>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </Typography>
            <br />
            <Typography>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Website, you have the right to request
              removal of unwanted data that you publicly post on the Website. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Website, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </Typography>
            <br />
            <Typography>
              <u>CCPA Privacy Notice</u>
            </Typography>
            <br />
            <Typography>
              The California Code of Regulations defines a "resident" as:
            </Typography>
            <br />
            <Typography>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </Typography>

            <Typography>
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </Typography>
            <br />
            <Typography>
              All other individuals are defined as "non-residents."
            </Typography>
            <br />
            <Typography>
              If this definition of "resident" applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.
            </Typography>
            <br />
            <Typography>
              What categories of personal information do we collect?
            </Typography>
            <br />
            <Typography>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </Typography>
            <br />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell className={classes.tableHeader}>
                      Category
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Examples
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      Collected
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.category}
                      </TableCell>
                      <TableCell align="right">{row.examples}</TableCell>
                      <TableCell align="right">{row.collected}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <br />
            <Typography>
              We may also collect other personal information outside of these
              categories instances where you interact with us in-person, online,
              or by phone or mail in the context of:
            </Typography>
            <Typography>
              <ul>
                <li>Receiving help through our customer support channels;</li>
              </ul>
              <ul>
                <li>Participation in customer surveys or contests; and</li>
              </ul>
              <ul>
                <li>
                  Facilitation in the delivery of our Services and to respond to
                  your inquiries.
                </li>
              </ul>
            </Typography>
            <Typography>
              How do we use and share your personal information?
            </Typography>
            <br />
            <Typography>
              Made to Uplift collects and shares your personal information
              through:
            </Typography>
            <Typography>
              <ul>
                <li>Targeting cookies/Marketing cookies</li>
              </ul>
              <ul>
                <li>Social media cookies</li>
              </ul>
              <ul>
                <li>Beacons/Pixels/Tags</li>
              </ul>
            </Typography>
            <Typography>
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </Typography>
            <br />
            <Typography>
              You may contact us by email at info@madetouplift.com, or by
              referring to the contact details at the bottom of this document.
            </Typography>
            <br />
            <Typography>
              If you are using an authorized agent to exercise your right to
              opt-out we may deny a request if the authorized agent does not
              submit proof that they have been validly authorized to act on your
              behalf.
            </Typography>
            <br />
            <Typography>
              Will your information be shared with anyone else?
            </Typography>
            <br />
            <Typography>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </Typography>
            <br />
            <Typography>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be "selling" of your personal data.
            </Typography>
            <br />
            <Typography>
              Made to Uplift has disclosed the following categories of personal
              information to third parties for a business or commercial purpose
              in the preceding twelve (12) months:
            </Typography>
            <Typography>
              <ul>
                <li>
                  Category A. Identifiers, such as contact details, like your
                  real name, alias, postal address, telephone or mobile contact
                  number, unique personal identifier, online identifier,
                  Internet Protocol address, email address and account name.
                </li>
              </ul>
              <ul>
                <li>
                  Category B. Personal information, as defined in the California
                  Customer Records law, such as your name, contact information,
                  education, employment, employment history and financial
                  information.
                </li>
              </ul>
              <ul>
                <li>
                  Category D. Commercial information, such as transaction
                  information, purchase history, financial details and payment
                  information.
                </li>
              </ul>
              <ul>
                <li>
                  Category K. Inferences drawn from any of the personal
                  information listed above to create a profile or summary about,
                  for example, an individual's preferences and characteristics.
                </li>
              </ul>
            </Typography>
            <Typography>
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under "<a href="#4">WHO WILL YOUR INFORMATION BE SHARED WITH?</a>
              ".
            </Typography>
            <br />
            <Typography>
              Made to Uplift has not sold any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months. Made to Uplift will not sell personal
              information in the future belonging to website visitors, users and
              other consumers.
            </Typography>
            <br />
            <Typography>
              Your rights with respect to your personal data
            </Typography>
            <br />
            <Typography>
              <u>Right to request deletion of the data - Request to delete</u>
            </Typography>
            <br />
            <Typography>
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation or
              any processing that may be required to protect against illegal
              activities.
            </Typography>
            <br />
            <Typography>
              <u>Right to be informed - Request to know</u>
            </Typography>
            <br />
            <Typography>
              Depending on the circumstances, you have a right to know:
            </Typography>
            <Typography>
              <ul>
                <li>whether we collect and use your personal information;</li>
              </ul>
              <ul>
                <li>the categories of personal information that we collect;</li>
              </ul>
              <ul>
                <li>
                  the purposes for which the collected personal information is
                  used;
                </li>
              </ul>
              <ul>
                <li>
                  whether we sell your personal information to third parties;
                </li>
              </ul>
              <ul>
                <li>
                  the categories of personal information that we sold or
                  disclosed for a business purpose;
                </li>
              </ul>
              <ul>
                <li>
                  the categories of third parties to whom the personal
                  information was sold or disclosed for a business purpose; and
                </li>
              </ul>
              <ul>
                <li>
                  the business or commercial purpose for collecting or selling
                  personal information.
                </li>
              </ul>
            </Typography>
            <Typography>
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request.
            </Typography>
            <br />
            <Typography>
              <u>
                Right to Non-Discrimination for the Exercise of a Consumer’s
                Privacy Rights
              </u>
            </Typography>
            <br />
            <Typography>
              We will not discriminate against you if you exercise your privacy
              rights.
            </Typography>
            <br />
            <Typography>
              <u>Verification process</u>
            </Typography>
            <br />
            <Typography>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. phone or
              email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate.
            </Typography>
            <br />
            <Typography>
              We will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. If, however, we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity, and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you.
            </Typography>
            <br />
            <Typography>
              <u>Other privacy rights</u>
            </Typography>
            <Typography>
              <ul>
                <li>you may object to the processing of your personal data</li>
              </ul>
              <ul>
                <li>
                  you may request correction of your personal data if it is
                  incorrect or no longer relevant, or ask to restrict the
                  processing of the data
                </li>
              </ul>
              <ul>
                <li>
                  you can designate an authorized agent to make a request under
                  the CCPA on your behalf. We may deny a request from an
                  authorized agent that does not submit proof that they have
                  been validly authorized to act on your behalf in accordance
                  with the CCPA.
                </li>
              </ul>
              <ul>
                <li>
                  you may request to opt-out from future selling of your
                  personal information to third parties. Upon receiving a
                  request to opt-out, we will act upon the request as soon as
                  feasibly possible, but no later than 15 days from the date of
                  the request submission.
                </li>
              </ul>
            </Typography>
            <Typography>
              To exercise these rights, you can contact us by email at
              info@madetouplift.com, or by referring to the contact details at
              the bottom of this document. If you have a complaint about how we
              handle your data, we would like to hear from you.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="12">12. DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </Typography>
            <br />
            <Typography>
              <em>
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
              </em>
            </Typography>
            <br />
            <Typography>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="13">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
            </Typography>
            <br />
            <Typography>
              If you have questions or comments about this notice, you may email
              us at info@madetouplift.com or by post to:
            </Typography>
            <br />
            <Typography>
              Made to Uplift
              <br />
              9324 Neils Thompson Dr
              <br />
              Ste 100 FSC 355
              <br />
              Austin, TX 78703
              <br />
              United States
            </Typography>
            <br />
            <Typography className={classes.subtitle}>
              <a id="14">
                14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </a>
            </Typography>
            <br />
            <Typography>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please submit a request form by contacting
              us{/* clicking &nbsp; */}
              {/* <a href="" rel="noopener noreferrer" target="_blank">
                here
              </a> */}
              .
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  return <Page name="Privacy" content={content()} />
}

export default withSnackbar(Home)
